/*body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}*/


html {
  /*background: url("./images/01_PreloadBackground.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
  -webkit-user-select:none;
}






@media (max-width:10000px) {
  body {
    background: url("./client-data/images/ExhibitionBackgroundImage.jpg") no-repeat center center fixed;
    /*background: url("./images/MichaelFolbsTestRender12.jpg") no-repeat center center fixed;*/
  }
}

@media (max-width:992px) {
  body {
    background: url("./client-data/images/ExhibitionBackgroundImage.jpg") no-repeat center center fixed;
  }
}

@media (max-width:768px) {
  body {
    background: url("./client-data/images/Laptop_ExhibitionBackgroundImage.jpg") no-repeat center center fixed;
  }
}

@media (max-width:576px) {
  body {
    background: url("./client-data/images/TabletLandscape_ExhibitionBackgroundImage.jpg") no-repeat center center fixed;
  }
}

@media (max-width:414px) {
  body {
    background: url("./client-data/images/Mobile_ExhibitionBackgroundImage.jpg") no-repeat center center fixed;
  }
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*background: white;*/

  /*background: cover;*/
  font-family: roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  /*background-size: cover;*/
  background-size: 100% 100%
}

/*this code was making the touch not working when rotating the scene*/
/*body {
  -webkit-touch-callout: none;                !* prevent callout to copy image, etc when tap to hold *!
  -webkit-text-size-adjust: none;             !* prevent webkit from resizing text to fit *!
  -webkit-user-select: none;                  !* prevent copy paste, to allow, change 'none' to 'text' *!
}*/



/**/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


:root {
  --bg:  #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

ul.v21-navbar-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;;
}

li.v21-nav-item {

}

.v21-button-menu-item {
  color: var(--text-color);
  text-decoration: none;
  background-color: rgba(255,255,255,0);
  border: none;
  align-items: center;
  justify-content: center;
  outline: none !important; box-shadow: none !important;



}

/*button:focus { outline: none !important; box-shadow: none !important; }*/

/* Top Navigation Bar */

/* <nav> */
.v21-navbar-right {
  height: var(--nav-size);
  /*background-color: var(--bg);*/
  padding: 0 1rem;
  /*border-bottom: var(--border);*/
  position: absolute;
  bottom: 35px;
  right: 5px;
  background-color: rgba( 255, 255, 255, 0.00);
  z-index: 50;
}

.v21-navbar-left {
  height: var(--nav-size);
  /*background-color: var(--bg);*/
  padding: 0 1rem;
  /*border-bottom: var(--border);*/
  position: absolute;
  bottom: 35px;
  left: 5px;
  background-color: rgba( 255, 255, 255, 0.00);
  z-index: 50;
}

/* <ul> */
.v21-navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  /*opacity: 1;*/
  flex-direction: row-reverse;
  /*background-color: red;*/
}

/* <li> */
.v21-nav-item {
  width: calc(var(--nav-size) * .8);
  display: flex;
  align-items: center;
  justify-content: center;

}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.6);
  width: var(--button-size);
  height: var(--button-size);
  /*background-color: #484a4d;*/
  background-color: rgba( 0, 0, 0, .1);
  border-radius: 50%;
  /*padding: 5px;*/
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: var(--text-color);
  width: 20px;
  height: 20px;
  /*margin: 5px;*/
}


/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 58px;
  width: 50px;
  transform: translateY(-124%) translateX(-8%);
  /*background-color: var(--bg);*/
  background-color: rgba( 0, 0, 0, .1);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height  var(--speed) ease;
}

.menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-basis: auto;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  /*padding: 0.5rem;*/
}

.menu-item .icon-button {
  margin-right: 0.1rem;
  margin-left: 0.1rem;
}


.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {

}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.circle-joy {
  /*padding:0;
  margin:0;
  position: absolute;
  left: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.75);
  !*!/ backgroundColor: '#ff0000',*!
  cursor: pointer;
  border-radius: 50%;
  user-select: none;
  outline: 0;*/

  /* position: absolute;
   bottom: 35px;
   width: 80px;
   height: 80px;
   padding:0;
   margin:0;
   !*border: rgba( 0, 0, 0, 0.15) solid medium;*!
   background-color: rgba(255, 255, 255, 0.75);
   border-radius: 50%;
   left: 50%;
   transform: translateX(-50%);
   user-select: none;*/
}

.help-dialog.modal-header {
  border-bottom: 0 none;
}
.loading-progress-modal-backdrop.modal-body {
  /*background-color: transparent !important;*/
  background-color: rgba(0,0,0,.0001) !important;
  /*opacity:0.1 !important;*/
}

@media screen and (min-width: 300px) {
  .v21-loading-panel {
    position: fixed;
    width: 250px;
    height: 250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
    /*background-color: yellow;*/
  }
}

@media screen and (min-width: 800px) {
  .v21-loading-panel {
    position: fixed;
    width: 300px;
    height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
  }
}

.v21-loading-logo {
  /*position: fixed;*/
  /*background-color: black;*/
  /*width: 500px;*/
  /*height: 500px;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%,-50%);*/
  /*opacity: 1;*/
  /*margin: 10px;*/
  width: 100%;
  height: 100%;
  padding: 50px;
}

/* Entry modal */

@media screen and (max-width:1000px) {
  .v21-entry-panel {
    position: fixed;
    width: 300px;
    height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
    /*background-color: yellow;*/
  }
}

@media screen and (min-width: 1001px) {
  .v21-entry-panel {
    position: fixed;
    width: 500px;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
  }
}

.v21-entry-logo {
  /*position: fixed;*/
  /*background-color: black;*/
  /*width: 500px;*/
  /*height: 500px;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%,-50%);*/
  /*opacity: 1;*/
  /*margin: 10px;*/
  width: 100%;
  height: 100%;
  cursor: pointer;
}
/* Entry modal */

/*  used for CSS3d annotation*/
.embedded-annotation {
  background-color: blue;
}

.css3d-annotaion-container {
  -webkit-user-select:none;
  width: 1000px;
  height: 1025px;
  background-color: rgba(255,255,255,1);
  position: absolute;


}

.css3d-annotaion-container-sub {
  /*top:50%;
  bottom: 50%;
  transform: translate(25%,25%);
  position: relative;*/
}

.css3d-media-container {
  height: 700px;
  width: 100%;
  position: absolute;

  top: 0;
}

.css3d-text-container {
  height: 200px;
  width: 100%;
  position: absolute;
  top: 700px;

}

.css3d-more-container {
  width: 100%;
  position: absolute;
  /*height: 100px;*/
  /*bottom: 0;*/
  top:900px;

}

.css3d-annotaion-sub-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 900px;
  transform: translateX(50px);

}

.css3d-media-sub {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.css3d-text-sub {
  padding: 10px;
  /* width: 100%;
   height: 100%;*/
  overflow-y: scroll;
  width: 773px;
  margin-top: 10px;
  height: 178px;
}

.css3d-text-head {
  font-family: Jost;
  font-weight: normal;
  font-style: normal;
  padding: 0px;
  width: 900px;
  font-size: 35px;

}

.css3d-text-body {

  padding-left: 10px;
  padding-right: 10px;
  font-family: jost;
  font-weight: normal;
  font-style: normal;
}

.css3d-more-sub {
  padding: 20px;
  filter: blur(0px);width: 773px;
}

.css3d-media-sub img {
  display: block;
  max-width:850px;
  max-height:680px;
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  /*padding:5px;*/
  /*border: rgba( 0, 0, 0, 1) solid medium;*/
  padding-top: 50px;
  padding-bottom: 20px;
  /*width: 5%;*/
}


/*#AnnotationVideoPlayer {
  display: block;
  max-width:850px;
  max-height:478px;
  width: 850px;
  height: 478px;
  !*margin-left: auto;*!
  !*margin-right: auto;*!
  !*padding:5px;*!
  !*border: rgba( 0, 0, 0, 1) solid medium;*!
  padding-top: 50px;
  padding-bottom: 20px;
  !*width: 5%;*!
}*/

/*.css3d-media-sub img {
  padding-top: 20px;

}*/

.css3d-more-sub h3 {
  font-family: jost;font-weight: bold;font-style: normal;filter: blur(0px) brightness(0%);font-size: 25px;

}



/* Annotation page css begin */

.annotation-main-container {
  height: 1000px;
  width: 700px;
  background-color: white;
  position: absolute;
  left:0;
  -webkit-user-select:none;
}

.annotation-main-container-modal {
  /* height: 1000px;
   width: 700px;*/
  background-color: white;
  /* position: absolute;
   left:0;*/
  -webkit-user-select:none;
}

@media (min-width: 601px) {
  .annotation-main-container {
    /*height: 71vh;*/
    max-width: 31vw;
    background-color: white;
    padding: 2vh 1vw;
  }
}

@media (max-width: 600px) {
  .annotation-main-container {
    /*height: 83vh;*/
    max-width: 90vw;
    background-color: white;
    padding: 2vh 1vw;
  }
}

.annotation-media-container {
  max-height: 41vh;
  /*background-color: red;*/
  /*max-width: 80vw;*/
  margin-bottom: 2vh;
}

@media (min-width: 601px) {
  .annotation-image-wrapper img,video,iframe {
    display: block;
    max-height: 41vh;
    /*min-height: 41vh;*/ /* This made the portfolio and image bigger, to be tested*/
    margin: 0px auto;
    /*justify-content: center;*/
    /*max-width: 75vw;*/
    max-width: 75%;
    padding: 0;
  }
}

@media (max-width: 600px) {
  .annotation-image-wrapper img,video,iframe {
    display: block;
    max-height: 41vh;
    margin: 0 auto;
    padding: 0px;
    /*bottom: 0;*/
    max-width: 75%;
    justify-content: center;
  }
}

.annotation-text-container {
  /*max-height: 35vh;*/
  /*background-color: green;*/
  /*margin: 0 auto;*/
  /*width: 1000px;*/
}

/* Carousel CSS of ItemAnnotationModal*/
/*.carousel-indicators li {*/
/*  background-color: darkgray; !* this can be whatever color you want *!*/
/*}*/

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: #dadce1;
}
/* Carousel CSS of ItemAnnotationModal*/

@media (min-width: 601px) {
  .annotation-header-text-container {
    margin: 10px auto;
    max-width: 75%;
    /*justify-content: center;*/
    text-align: center;
    font-family: Jost;
    font-weight: bold;
    font-style: normal;
    font-size: 35px;
    /*padding: 0 10px;*/
    max-height: 6vh;

  }
}

@media (max-width: 600px) {
  .annotation-header-text-container {
    margin: 30px auto;
    max-width: 90%;
    /*justify-content: center;*/
    text-align: center;
    font-family: Jost;
    font-weight: bold;
    font-style: normal;
    font-size: 15px;
    /*padding: 0 10px;*/
    max-height: 6vh;

  }
}

@media (min-width: 601px) {
  .annotation-body-text-container {
    /*padding: 20px 50px;*/
    overflow-y: scroll;
    /*background-color: pink;*/
    max-height: 19vh;
    font-family: jost;
    font-weight: normal;
    font-style: normal;
    font-size: 15px;
    max-width: 75%;
    justify-content: center;
    text-align: center;
    margin: 1vh auto;
    padding: 10px 10px;
  }
}

@media (max-width: 600px) {
  .annotation-body-text-container {
    /*padding: 20px 50px;*/
    overflow-y: scroll;
    /*background-color: pink;*/
    max-height: 19vh;
    font-family: jost;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    max-width: 75%;
    justify-content: center;
    text-align: center;
    margin: 1vh auto;
    padding: 10px 10px;
  }
}
@media (min-width: 601px) {
  .annotation-social-media-container {
    /*padding: 20px 50px;*/
    /*overflow-y: scroll;*/
    /*background-color: pink;*/
    /*max-height: 19vh;*/
    font-family: jost;
    /*font-weight: bold;*/
    font-style: normal;
    font-size: 14px;
    max-width: 90%;
    justify-content: center;
    filter: blur(0px) brightness(0%);
    text-align: center;
    margin: 1vh auto;
    padding: 10px 10px;
  }
}

@media (max-width: 600px) {
  .annotation-social-media-container {
    /*padding: 20px 50px;*/
    /*overflow-y: scroll;*/
    /*background-color: pink;*/
    /*max-height: 19vh;*/
    font-family: jost;
    /*font-weight: bold;*/
    font-style: normal;
    font-size: 10px;
    max-width: 90%;
    justify-content: center;
    filter: blur(0px) brightness(0%);
    text-align: center;
    margin: 1vh auto;
    padding: 10px 10px;
  }
}


.annotation-text-edition {
  max-height: 4vh;
  max-width: 75vw;
  margin: 0 auto;
  /*justify-content: center;*/
  text-align: center;
  padding: 0 1vw;
  font-style: normal;
  font-size: 15px;
}

.annotation-text-price {
  max-height: 4vh;
  max-width: 75vw;
  margin: 0 auto;
  text-align: center;
  padding: 0 1vw;
  font-style: normal;
  font-size: 15px;
}

.annotation-contact-container {
  /*position: absolute;*/
  max-height: 4.5vh;
  max-width: 90%;
  /*background-color: brown;*/
  margin: 2vh auto 1vh auto;
  padding-bottom: 10%;
  text-align: center;
  font-family: jost;
  font-weight: bold;
  font-style: normal;
  filter: blur(0px) brightness(0%);
  font-size: 15px;
  /*padding: 20px 10px 50px 10px;*/
}

.loading-ProgressBar {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

/*#GLTFViewer:-webkit-full-screen {
 position: static;
}

#GLTFViewer:-moz-full-screen {
  position: static;
}

!* IE/Edge syntax *!
#GLTFViewer:-ms-fullscreen {
  position: static;
}

!* Standard syntax *!
#GLTFViewer:fullscreen {
  position: static;
}*/

@media (min-width:992px) {
  .annotation-modal-classname {
    /*width: 702px;*/
  }
}

.modal-dialog {
  overflow-y: initial !important
}

/* Annotation page css end */

@media (min-width: 601px) {
  .produced-by-v21artspace {
    position: absolute;
    max-height: 20px;
    bottom: 20px;
    left: 34px;
    text-align: center;
    font-family: jost;
    font-weight: bold;
    font-style: normal;
    /*filter: blur(0px) brightness(0%);*/
    font-size: 10px;
    color:white;
    z-index: 100;
  }
}

@media (max-width: 600px) {
  .produced-by-v21artspace {
    position: absolute;
    max-height: 20px;
    bottom: 5px;
    left: 5px;
    text-align: center;
    font-family: jost;
    font-weight: bold;
    font-style: normal;
    /*filter: blur(0px) brightness(0%);*/
    font-size: 10px;
    color:white;
    z-index: 100;
  }
}

.zone_links {
  text-align: center;
  /*.link { color: #FF0000; }*/
}

.v21-logo:hover {
  color: #FFC417;
}
